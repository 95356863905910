export default [
  {
    path: '/marketManagement',
    name: 'marketManagement',
    component: () => import(/* webpackChunkName: "marketManagement" */ './index'),
    meta: {
      type: 'marketManagement'
    },
    redirect: '/marketManagement/materialList',
    children: [
      {
        path: 'materialList',
        name: 'materialList',
        component: () => import(/* webpackChunkName: "marketManagement" */ './materialList')
      },
      {
        path: 'programList',
        name: 'programList',
        component: () => import(/* webpackChunkName: "marketManagement" */ './programList')
      }
    ]
  }
]
